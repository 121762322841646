import getMuiTheme from 'material-ui/styles/getMuiTheme';

const muiTheme = getMuiTheme({
  fontFamily: 'Lato',
  palette: {
    textColor: '#536e7b',
  },
  menuItem: {
    selectedTextColor: '#263238',
  },
  tableRow: {
    stripeColor: '#f6f9fb',
  },
  checkbox: {
    boxColor: '#3f5a67',
    checkedColor: '#3f5a67',
  },
  tabs: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  raisedButton: {
    primaryColor: '#c4105a',
    primaryTextColor: '#fff',
    secondaryColor: '#ff9900',
    secondaryTextColor: '#fff',
  },
  flatButton: {
    primaryTextColor: '#ff9900',
  },
  button: { textTransform: 'none' }
});

export default muiTheme;
